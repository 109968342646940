import { Controller } from "@hotwired/stimulus"
import DialogController from "./dialog_controller"

export default class extends Controller {
  static outlets = ["dialog"]
  static values = {
    type: { type: String, default: "panel" },
    href: String,
  }

  connect() {
    if (this.hasDialogOutlet) return

    const element = DialogController.ensureDialog(this.typeValue)
    const selector = element.className.replace(/^|\s+/g, ".")
    this.element.dataset.dialogButtonDialogOutlet = selector
  }

  click() {
    this.dialogOutlet.openDialog(this.hrefValue)
  }
}
