import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "selectField",
    "sideContainer",
    "count",
    "list",
    "item",
    "moveButton",
    "iconTemplate",
    "iconContainer",
  ]

  static values = { selectFieldSourceSide: String }

  elementSide(element) {
    const container = this.sideContainerTargets.find(containerTarget => containerTarget.contains(element))
    return container.dataset.side
  }

  getOtherSide(sideValue) {
    return sideValue === 'left' ? 'right' : 'left'
  }

  sideContainer(sideValue) {
    return this.sideContainerTargets.find(containerTarget => containerTarget.dataset.side === sideValue)
  }

  elementList(element) {
    const side = this.elementSide(element)
    const container = this.sideContainer(side)
    return this.listTargets.find(listTarget => container.contains(listTarget))
  }

  otherList(element) {
    const otherSide = this.getOtherSide(this.elementSide(element))
    const container = this.sideContainer(otherSide)
    return this.listTargets.find(listTarget => container.contains(listTarget))
  }

  elementIconTemplate(element) {
    const side = this.elementSide(element)
    const container = this.sideContainer(side)
    return this.iconTemplateTargets.find(iconTemplate => container.contains(iconTemplate))
  }

  move(event, defer_updates = false) {
    const button = event.currentTarget
    const item = this.itemTargets.find(itemTarget => itemTarget.contains(button))
    const iconContainer = this.iconContainerTargets.find(iconContainer => item.contains(iconContainer))
    const destinationList = this.otherList(item)
    const destinationIconTemplate = this.elementIconTemplate(destinationList)
    destinationList.appendChild(item)
    iconContainer.innerHTML = destinationIconTemplate.innerHTML
    if (!defer_updates) {
      this.updateSelect()
      this.updateCounts()
    }
  }

  moveAll(event) {
    event.preventDefault()
    const button = event.currentTarget
    const sourceList = this.elementList(button)
    const sourceListMoveButtons = this.moveButtonTargets.filter(button => sourceList.contains(button))
    sourceListMoveButtons.forEach(moveButton => {
      this.move({ currentTarget: moveButton }, true)
    })
    this.updateSelect()
    this.updateCounts()
  }

  updateSelect() {
    const container = this.sideContainer(this.selectFieldSourceSideValue)
    const selectFieldSourceList = this.listTargets.find(list => container.contains(list))
    const selectedIds = Array.from(selectFieldSourceList.children).map(item => item.dataset.optionValue)

    Array.from(this.selectFieldTarget.options).forEach(option => {
      option.selected = selectedIds.includes(option.value)
    })
    this.selectFieldTarget.dispatchEvent(new Event('change'))
  }

  updateCounts() {
    this.countTargets.forEach(countTarget => {
      const list = this.elementList(countTarget)
      countTarget.textContent = list.children.length
    })
  }
}
