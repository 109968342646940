// Controller for a single dropdown menu, actuated by a toggle button

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { open: Boolean }

  static targets = [
    'menuToggle',
    'menuContent'
  ]

  toggleDropdown() {
    if (this.openValue)
      this.closeDropdown()
    else
      this.openDropdown()
  }

  openDropdown() {
    if (!this.element.checkVisibility())
      return
    this.menuToggleTarget.ariaExpanded = 'true'
    this.menuContentTarget.ariaHidden = 'false'
    this.openValue = true
  }

  closeDropdown() {
    if (!this.element.checkVisibility())
      return
    this.menuToggleTarget.ariaExpanded = 'false'
    this.menuContentTarget.ariaHidden = 'true'
    this.openValue = false
  }
}
