import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["frame"]

  static ensureDialog(type) {
    let dialog = document.querySelector(`dialog:has(#${type})`)
    if (!dialog) {
      dialog = document.createElement("dialog")
      dialog.className = `ic-dialog ic-dialog_${type}`
      dialog.dataset.controller = "dialog"

      const button = document.createElement("button")
      button.className = "ic-dialog__close"
      button.autofocus = true
      button.dataset.action = "dialog#closeDialog"

      const x = document.createElement("span")
      x.className = "ic-dialog__close-x"
      x.innerHTML = "&#x2715;"

      const frame = document.createElement("turbo-frame")
      frame.className = type == "modal" ? "ic-dialog__iframe" : "ic-dialog__turboframe"
      frame.id = type
      frame.dataset.dialogTarget = "frame"
      frame.dataset.action = "turbo:before-fetch-response->dialog#handleResponse"

      button.append(x)
      dialog.append(button)
      dialog.append(frame)
      document.body.append(dialog)
    }

    return dialog
  }

  openDialog(href) {
    const url = new URL(href, location.origin).toString()
    if (this.frameTarget.src != url) {
      this.frameTarget.innerHTML = ""
      Turbo.visit(url, { frame: this.frameTarget.id })
    }

    this.element.showModal()
  }

  closeDialog() {
    this.element.close()
  }

  handleResponse(event) {
    const response = event.detail.fetchResponse
    const location = response.header("Turbo-Frame-Redirect")
    if (location) {
      Turbo.visit(location)
      event.preventDefault()
    }
  }
}
